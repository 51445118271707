<template>
    <ul :class="[ 'filter-list', propsClass ]">
        <li v-for="item in dataList"
            :key="item.id">
            <a href="javascript:;"
                @click="handleClick(item.value)"
                :class="{selected: selectedFilter == item.value}">
                {{item.label}}
            </a>
        </li>
    </ul>
</template>

<script>
    export default {
        props: {
            dataList: Array,
            selectedFilter: [String, Number],
            propsClass: {
                type: String,
                default: ''
            }
        },
        methods: {
            handleClick(val){
                this.$emit('handleFilter', val);
            }
        },
    }
</script>

<style scoped>
.filter-list{
    display: flex;
    justify-content: center;
}
.filter-list > li + li{
    border-left: 1px solid #EBEEF5;
}
.filter-list a{
    display: block;
    padding: 0 20px;
    letter-spacing: 4px;
    font-size: 22px;
    color: #303133;
}
.filter-list a:hover,
.filter-list .selected{
    color: var(--color);
}
</style>